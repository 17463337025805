import { graphql, navigate } from 'gatsby'
import React, { FC, useEffect } from 'react'
import { Helmet } from 'react-helmet'

import useBolCookies from '../hooks/useBolCookies'
import * as SanitySchema from '../lib/types/sanity-schema'

export const query = graphql`
  query FrontPageQuery {
    site: sanitySiteSettings(_id: { eq: "siteSettings" }) {
      title
      language
      openGraph {
        title
        description
      }
    }
  }
`

interface IFrontPageProps {
  data: {
    site: SanitySchema.SiteSettings
  }
}

const IndexPage: FC<IFrontPageProps> = ({ data }) => {
  const { getBolLanguage } = useBolCookies()
  useEffect(() => {
    const lang = getBolLanguage()
    navigate(`/${lang || 'es'}/`)
  }, [getBolLanguage])

  return (
    <>
      <Helmet>
        <title>{data.site?.openGraph?.title}</title>
        <noscript>{`<meta http-equiv='refresh' content='0;url=/es/' />`}</noscript>
        <style type="text/css">
          {`
          .container {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-content: space-around;
            flex-direction: column;
            gap: 1em;
          }
          .links {
            display: flex;
            flex-direction: column;
            align-content: center;
            justify-content: center;
            text-align: center;
            font-size: 0.8em;
            opacity: 0;
          }
          a {
            text-decoration: none;
          }
          .logo  {
            max-width: 200px;
            align-self: center;
            animation: fadein 0.5s ease-in-out;
          }
          @keyframes fadein {
            0% {
              opacity: 0;
            }
            100% {
              opacity: 1;
            }
          }`}
        </style>
      </Helmet>

      <section className="container">
        <img
          className="logo"
          alt="Laboral Kutxa, Inicio"
          src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTExIiBoZWlnaHQ9IjEyNSIgdmlld0JveD0iMCAwIDExMSAxMjUiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGZpbGwtcnVsZT0iZXZlbm9kZCIgY2xpcC1ydWxlPSJldmVub2RkIiBkPSJNMC4wMzIxNjU1IDc2LjUzOTlMMTEwLjAzMiAwVjQ4LjQ2MDFMMC4wMzIxNjU1IDEyNVY3Ni41Mzk5WiIgZmlsbD0iIzg0QkQwMCIvPgo8cGF0aCBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGNsaXAtcnVsZT0iZXZlbm9kZCIgZD0iTTAuMDMyMTY1NSA1NUgxMTAuMDMyVjk1SDAuMDMyMTY1NVY1NVoiIGZpbGw9IiNBNTAwNTAiLz4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0zMC4xNjkyIDU1SDEwMC4wMzJMNDIuNTI5MyA5NUgwLjAzMjE2NTVMMC4wMzIxNjU1IDc1LjkwOTFMMzAuMTY5MiA1NVoiIGZpbGw9IiM1MTI4NEYiLz4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0zNi42MjggNjAuMTgxNUMzNi40ODIxIDYwLjc1NzEgMzYuNDA1NCA2MS4zNTM2IDM2LjQwNTQgNjEuOTY0OUMzNi40MDU0IDYzLjEwNDcgMzYuNjcyIDY0LjE5MzIgMzcuMTU2OCA2NS4xOTA0QzM2LjMwMjkgNjQuODQyNCAzNS4zMjEgNjQuNjQ0MyAzNC4yNzYyIDY0LjY0NDNDMzAuOTgzOCA2NC42NDQzIDI4LjMxNDcgNjYuNjExNyAyOC4zMTQ3IDY5LjAzODZDMjguMzE0NyA3MS40NjU1IDMwLjk4MzggNzMuNDMyOCAzNC4yNzYyIDczLjQzMjhDMzcuNTY4NyA3My40MzI4IDQwLjIzNzggNzEuNDY1NSA0MC4yMzc4IDY5LjAzODZDNDAuMjM3OCA2OC45MDYyIDQwLjIyOTggNjguNzc1MyA0MC4yMTQzIDY4LjY0NTlDNDEuODAyMiA2OS43MzI0IDQzLjc4MzYgNzAuNDQyMiA0NS45NTY0IDcwLjYwODZDNDQuODE2OSA3NC4xNDc1IDQwLjY0MjUgNzYuODU3NyAzNS40OTY1IDc3LjI2NDhMMzUuNjUzNSA3OS45NTkxQzM1LjY1MzUgNzkuOTU5MSAzNi4xNjU2IDg0LjYxNzYgMzguMzYyMyA4NC40MDM4QzQwLjEzMzggODQuMTkgNDEuMTk0NSA4My4xNTU5IDQxLjE5NDUgODMuMTU1OUw0MS40OTM1IDkwSDMwLjUzNjdMMzEuMjM3MyA4My4zMTQ2TDMxLjU2OTYgNzcuMTIxMkMyNi4xMTY5IDc2LjI3NjUgMjIuMDMyMiA3Mi44MDg2IDIyLjAzMjIgNjguNjU4OUMyMi4wMzIyIDYzLjg3NjcgMjcuNDU2OCA2MCAzNC4xNDg1IDYwQzM0Ljk5ODMgNjAgMzUuODI3NiA2MC4wNjI1IDM2LjYyOCA2MC4xODE1WiIgZmlsbD0id2hpdGUiLz4KPC9zdmc+Cg=="
        />
        <nav className="links">
          <a href="/es/">Laboral Kutxa (Castellano)</a>
          <a href="/eu/">Laboral Kutxa (Euskara)</a>
          <a href="/ca/">Laboral Kutxa (Catalán)</a>
        </nav>
      </section>
    </>
  )
}
export default IndexPage
