// Helper functions for the rest of the code (this file will be public)

// IMPORTANT: it only must reference to GATSBY_* vars (others must be on ./src/gatsby/gatsbty-lk-config)
export interface SanityLkWebConfig {
  projectId: string
  dataset: string
}
export const getSanityClientLkConfig = (): SanityLkWebConfig => ({
  projectId: process.env.GATSBY_SANITY_STUDIO_API_PROJECT_ID || `CONFIG_NOT_SET`,
  dataset: process.env.GATSBY_SANITY_STUDIO_API_DATASET || `CONFIG_NOT_SET`,
})

export const getBaseUrlLkConfig = (): string => process.env.GATSBY_BASE_URL || `CONFIG_NOT_SET`

export interface SearchServiceLkWebConfig {
  endpoint: string
  indexName: string
  apiKey: string
}
export const getSearchServiceLkConfig = (): SearchServiceLkWebConfig => ({
  endpoint: process.env.GATSBY_AZURE_SEARCH_ENDPOINT || `CONFIG_NOT_SET`,
  indexName: process.env.GATSBY_AZURE_SEARCH_DATASET || 'CONFIG_NOT_SET',
  apiKey: process.env.GATSBY_AZURE_SEARCH_KEY || `CONFIG_NOT_SET`,
})

export interface ChatLkWebConfig {
  'lk-chat': string
  'lagunaro-chat': string
}
export const getChatLkConfig = (): ChatLkWebConfig => ({
  'lk-chat': process.env.GATSBY_LIVEBEEP_URL_LK,
  'lagunaro-chat': process.env.GATSBY_LIVEBEEP_URL_LAGUNARO,
})

export const getReCaptchaKeyLkConfig = (): string => process.env.GATSBY_RECAPTCHA_KEY || `CONFIG_NOT_SET`

export const getLkApisBaseUrlLkConfig = (): string => process.env.GATSBY_LK_APIS_BASE_URL || `CONFIG_NOT_SET`

export const getGoogleMapsApiKeyLkConfig = (): string => process.env.GATSBY_GOOGLE_MAPS_API_KEY || `CONFIG_NOT_SET`

export const getSalesForceUrlLkConfig = (): string => process.env.GATSBY_SALESFORCE_URL || `CONFIG_NOT_SET`

export interface WeecoverLkConfig {
  url: string
  environment: string
  collective: string
  agentId: string
  subAgentId: string
}

export const getWeecoverLkConfig = (): WeecoverLkConfig => ({
  url: process.env.GATSBY_WEECOVER_URL || `CONFIG_NOT_SET`,
  environment: process.env.GATSBY_WEECOVER_ENVIRONMENT || `CONFIG_NOT_SET`,
  collective: process.env.GATSBY_WEECOVER_COLLECTIVE || `CONFIG_NOT_SET`,
  agentId: process.env.GATSBY_WEECOVER_AGENT_ID || `CONFIG_NOT_SET`,
  subAgentId: process.env.GATSBY_WEECOVER_SUBAGENT_ID || `CONFIG_NOT_SET`,
})

export const getBioCatchUrl = (): string => process.env.GATSBY_BIO_CATCH_URL || `CONFIG_NOT_SET`

export const isProd = process.env.NODE_ENV === `production`
export const isPreview = process.env.GATSBY_LK_IS_PREVIEW === 'true'

export type BolUrls = {
  becomeAClient: string
  bol: string
  login: string
  customizeActualCard: (cardId: string) => string
  customizeNewCard: (cardId: string) => string
}

export const getBolUrlsLkConfig = (): BolUrls => {
  const bolBaseUrl = process.env.GATSBY_LK_BOL_BASE_URL || `CONFIG_NOT_SET`
  return {
    becomeAClient: `${bolBaseUrl}/hazte-cliente`,
    bol: `${bolBaseUrl}/`,
    login: `${bolBaseUrl}/login`,
    customizeActualCard: (cardId: string) => `${bolBaseUrl}/posicion-total?image=${cardId}`,
    customizeNewCard: (cardId: string) => `${bolBaseUrl}/contratar-tarjeta?image=${cardId}`,
  }
}

export default {}
