import { getCookie, setCookie } from 'react-use-cookie'

import { getBaseUrlLkConfig } from '../lib/lkWebConfig'

export const LK_BOL_COOKIE_USER = 'LaboralKutxa_BOL_user'
export const LK_BOL_COOKIE_LANG = 'LaboralKutxa_idioma'

export interface BolUserInfo {
  userID: string
  userType: string
  [key: string]: string
}

const getBolUserInfo = (): BolUserInfo | null => {
  let userInfo = null
  try {
    const bolCookie = getCookie(LK_BOL_COOKIE_USER)
    if (bolCookie) {
      userInfo = JSON.parse(decodeURIComponent(bolCookie))
    }
  } catch (e) {
    console.warn('error parsing bol cookie', e)
  }
  return userInfo
}

const getBolLanguage = () => {
  try {
    const lang = getCookie(LK_BOL_COOKIE_LANG)
    return lang.split('-')[0] || null
  } catch (e) {
    return null
  }
}

const setBolLanguage = (language: string) => {
  if (!language) {
    return
  }
  const value = `${language.toLocaleLowerCase()}-ES`
  const baseUrl = getBaseUrlLkConfig()
  let domain
  try {
    const url = new URL(baseUrl)
    domain = url?.hostname?.includes('laboralkutxa.com') ? '.laboralkutxa.com' : url?.hostname
  } catch (e) {
    domain = null
  }
  setCookie(LK_BOL_COOKIE_LANG, value, { domain, Secure: true })
}

export default () => ({ setBolLanguage, getBolLanguage, getBolUserInfo })
